import React from 'react'

import Layout from '@components/layout/Layout'
import NarcolepsyFaqComponent from '@components/faqs/faq-glossary/faq-glossary.component'


const GlossaryOfTerms = ({ location }) => {
    return (
    <Layout location={location}>
        <NarcolepsyFaqComponent glossary />
    </Layout>
    )
}

export default GlossaryOfTerms
